import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { HelmetDatoCms } from 'gatsby-source-datocms';
import Layout from '../components/Layout';
import Banner from '../components/Banner';
import ModularBlocks from '../components/ModularBlocks';

const IndexPage = () => {
  const {
    datoCmsHome: { seoMetaTags, title, bannerMedia, bannerLink, modularBlocks },
  } = useStaticQuery(graphql`
    query IndexPageQuery {
      datoCmsHome {
        seoMetaTags {
          ...GatsbyDatoCmsSeoMetaTags
        }
        title
        bannerMedia {
          format
          url
          video {
            thumbnailUrl
            mp4Url
          }
          gatsbyImageData(width: 1250, height: 800)
          alt
          isImage
        }
        bannerLink {
          text
          page {
            ...LinkFragment
          }
        }
        modularBlocks {
          ...ContentModularBlockFragment
          ...ContentCtaFeaturedLinksModularBlockFragment
          ...FeaturedProjectsModularBlockFragment
          ...HighlightedLogosModularBlockFragment
          ...ImageContentModularBlockV1Fragment
          ...ImageContentModularBlockV2Fragment
          ...ImageCtaCardsModularBlockFragment
          ...LatestNewsModularBlockFragment
        }
      }
    }
  `);

  return (
    <Layout>
      <HelmetDatoCms seo={seoMetaTags} />
      <main>
        <Banner
          heading={title}
          link={bannerLink}
          media={bannerMedia}
          isHomepage={true}
        />
        <ModularBlocks items={modularBlocks} />
      </main>
    </Layout>
  );
};

export default IndexPage;
